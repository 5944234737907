<template>
  <div>
    <div class="content-search-purchase-orders-global-all">
      <div class="content-search-all">
        <v-select
          v-model="sSearchType"
          item-text="sName"
          item-value="sId"
          :items="aSearchType"
          class="custom-placeholder select-search-type-global mr-4"
          outlined
          dense
          background-color="#FAFAFA"
          :placeholder="'Buscar por...'"
          no-data-text="Sin información"
        >
        </v-select>
        <v-text-field
          class="custom-placeholder"
          outlined
          dense
          background-color="#FAFAFA"
          clearable
          v-model="sSearch"
          append-icon="mdi-magnify"
          :placeholder="
            sSearchType
              ? 'Búsqueda por ' + getNameProyectSelected(sSearchType)
              : 'Búsqueda general...'
          "
        >
        </v-text-field>
      </div>

      <div class="content-date-range-all">
        <v-menu
          ref="menu"
          v-model="bMenu"
          attach
          :close-on-content-click="false"
          :return-value.sync="aDates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="sDates"
              outlined
              chips
              clearable
              dense
              class="custom-placeholder"
              placeholder="Seleccionar rango de fechas..."
              color="primary"
              v-bind="attrs"
              v-on="on"
              readonly
            >
              <template slot="append">
                <v-icon @click="bMenu = true">mdi-calendar-blank</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="aDates"
            :max="sMaxDate"
            range
            no-title
            color="primary"
            locale="es"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="bMenu = false">
              Cancelar
            </v-btn>
            <v-btn
              :disabled="!bAddDates"
              text
              color="primary"
              @click="$refs.menu.save(aDates), save(aDates)"
            >
              <span :class="bAddDates ? 'color-yellow-global' : ''"
                >Guardar</span
              >
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>

      <v-spacer></v-spacer>

      <div class="content-btn-add-purchase-order">
        <v-btn
          color="primary"
          style="
              color: black;
              box-shadow: 0px 20px 40px #00000029;
              border-radius: 8px;
              font-family: 'Poppins', sans-serif !important;
              
              font-weight: 600;
              height: 40px;
            "
          @click="setCreateOrder"
          >Nueva orden
        </v-btn>
      </div>
    </div>
    <NewPurchaseOrder
      @close="openDialogOrder = false"
      :openDialogOrder="openDialogOrder"
    ></NewPurchaseOrder>
  </div>
</template>

<script>
import NewPurchaseOrder from "@/components/views/Requests/NewOrder";

export default {
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
  data() {
    return {
      openDialogOrder: false,
      areaChosen: null,
      statusChosen: null,
      sSearch: "",
      bMenuFinal: false,
      bMenuInitial: false,
      dateStart: null,
      dateEnd: null,

      sSearchType: "1",
      aSearchType: [
        {
          sId: "1",
          sName: "Proyecto",
        },
        {
          sId: "2",
          sName: "Proveedor",
        },
        {
          sId: "3",
          sName: "Método de pago",
        },
        {
          sId: "4",
          sName: "Material",
        },
      ],

      bMenu: false,
      aDates: [],
      sMaxDate: "",
      tStart: "",
      tEnd: "",

      sDates: "",
      dates: [],
      sMaxDate: new Date().toISOString().slice(0, 10),
      menu: false,
      bAddDates: false,
    };
  },
  components: {
    NewPurchaseOrder,
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setStatus", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    //  this.$store.commit("setDebtDays", null);
  },
  methods: {
    openDialogNewPurchaseOrder() {
      this.openDialogOrder = true;
    },
    setCreateOrder() {
      this.$router.push({
        name: "OrdersAddOrDetail",
        params: { sOCId: "Add" },
      });
    },
    save(aDates) {
      this.$refs.menu.save(aDates);
      if (this.aDates.length > 1) {
        let fecha_inicial = this.aDates[0];
        let fecha_final = this.aDates[1];
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.aDates[1];
          this.aDates = [];
          this.aDates[0] = lastDate;
        } else {
          this.tStart = fecha_inicial;
          this.tEnd = fecha_final;
          this.sDates = this.tStart + " - " + this.tEnd;
          this.$emit("getDateRange", {
            tStart: this.tStart,
            tEnd: this.tEnd,
          });
        }
      }
    },
    getNameProyectSelected(sId) {
      if (sId) {
        var oSearchType = this.aSearchType.filter((val) => val.sId === sId)[0];
        return oSearchType.sName.toLowerCase();
      } else {
        return "";
      }
    },
  },
  watch: {
    aDates() {
      if (this.aDates !== null) {
        if (this.aDates.length > 1) {
          this.bAddDates = true;
          if (this.aDates[0] > this.aDates[1]) {
            let fecha_inicial = this.aDates[1];
            this.aDates = [];
            this.aDates.push(fecha_inicial);
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.tStart = null;
        this.tEnd = null;
        this.$emit("getDateRange", {
          tStart: this.tStart,
          tEnd: this.tEnd,
        });
      }
    },
    sDates() {
      if (this.sDates == null) {
        this.aDates = [];
        this.$refs.menu.save([]);
        this.bAddDates = false;
        this.tStart = null;
        this.tEnd = null;
        this.$emit("getDateRange", {
          tStart: this.tStart,
          tEnd: this.tEnd,
        });
      }
    },
    statusChosen: function() {
      this.$store.commit("setStatus", this.statusChosen);
    },
    areaChosen: function() {
      this.$store.commit("setArea", this.areaChosen);
    },
    dateStart: function() {
      this.dateEnd = null;
      this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function() {
      this.$store.commit("setEndDate", this.dateEnd);
    },
    sSearchType() {
      this.sSearch = "";
      this.$emit("setSearchType", this.sSearchType);
    },
    // set state to local search
    sSearch() {
      this.$store.commit("setSearch", this.sSearch);
    }
    // sSearch: lodash.debounce(function(val) {
    //   this.$store.commit("setSearch", this.sSearch);
    // }, 800),
  },
};
</script>
<style>
.charge-input .v-input__prepend-outer {
  display: none;
}
.v-select__slot,
.v-text-field .v-label {
  font-family: "Poppins", sans-serif;
}
.v-input__slot fieldset {
  background-color: #fafafa;
  border: 1px solid #f2f2f2 !important;
  border-radius: 10px;
}

.mdi-magnify,
.mdi-calendar {
  font-size: 19px !important;
  color: #242424 !important;
}

.FieldLabel {
  text-align: left;
  font: normal normal normal 12px/14px "Poppins", sans-serif;
  margin-bottom: 0px !important;
  margin-left: 20px;
  z-index: 100;
  color: #008b83;
  opacity: 1;
}
.custom-placeholder input::placeholder {
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
.custom-placeholder input::-moz-placeholder {
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
</style>
<style scoped>
.content-search-purchase-orders-global-all {
  display: flex;
  width: 100%;
}
.select-search-type-global {
  width: 200px;
}
.content-search-all {
  display: flex;
  margin-right: 20px;
}

.texts-inputs {
  margin-bottom: -10px;
}

.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}

.button-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  background: #ffffff;
  cursor: pointer;
  color: #737373;
  padding: 5px 25px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  border: 2px solid #737373;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

#fileToUpload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
</style>
